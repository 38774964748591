module.exports = [{
      plugin: require('/codebuild/output/src949305436/src/codecabulary/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W7FBGQC"},
    },{
      plugin: require('/codebuild/output/src949305436/src/codecabulary/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src949305436/src/codecabulary/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
